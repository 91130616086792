// Imports ---------------------------------------------------------------------

import React from 'react';
import './HighlightsSection.css';
import './ConjureDemo.css';

// conjure
import ConjureFormController from 'conjure-form';
import SAMPLE_FORM_YC from '../../../ConjureForms/yc.json';


// logos
import CONJURE_LOGO       from '../../../Images/conjure-logo.png';
import INDIEOUTREACH_LOGO from '../../../Images/indieoutreach-icon.png';
import PGSTATS_LOGO       from '../../../Images/pgstats-logo.svg';

// Data ------------------------------------------------------------------------


const CONJURE = {
  title: "Conjure Forms",
  logo: CONJURE_LOGO,
  links: [
    {name: 'github', link: 'https://github.com/darrendawson/conjure-forms'},
    {name: 'npm', 'link': 'https://www.npmjs.com/package/conjure-form'},
    {name: 'website', 'link': 'https://conjureforms.com/'}
  ],
  primaryColor: "#EA7A29",
  secondaryColor: "#FCD1AA",
  primaryDescription: "Predictable, programmable JSON forms for React.js",
  secondaryDescription: "Makes collecting complex user input as simple as `npm install`",
  bulletPoints: [
    "Bundle related questions into the same JSON object and reuse it as a template",
    "Ask questions (or templated groups of questions) and get results as a list",
    "Conditionally show questions based on answers to previous questions",
    "Have users select available options from dropdowns"
  ],
  tech: ["React.js", "NPM"]
};



const INDIEOUTREACH = {
  title: "IndieOutreach",
  logo: INDIEOUTREACH_LOGO,
  links: [
      {name: 'website', 'link': 'http://indieoutreach.io'}
  ],
  primaryColor: "#B45AFB",
  secondaryColor: "#CEAAFC",
  primaryDescription: "A search engine that makes game PR on Twitch a breeze",
  secondaryDescription: "Matches indie games to the streamers who are historically most likely to play them on-stream",
  bulletPoints: [
    "Uses the Twitch and IGDB APIs to build up a history of what games streamers have played and when they played them",
    "Game devs describe their game intuitively (ie: it's a voxel game and is like Mario 3D World meets God of War and its a Kickstarter)",
    "IndieOutreach sorts through all of its recorded streams and presents streamers that fit the specified description",
    "Enables filtering by useful metrics for indie devs like 'variety streamer' or 'plays kickstarter games'"
  ],
  tech: ["React.js", "Express.js", "Python", "MySQL", "SQLite", "AWS RDS", "AWS EB"]
};


const PGSTATS = {
  title: "PGstats.com",
  logo: PGSTATS_LOGO,
  links: [
      {name: 'website', 'link': 'https://pgstats.com'}
  ],
  primaryColor: "#29BBEA",
  secondaryColor: "#AAE8FC",
  primaryDescription: "The #1 resource for the competitive Smash Bros community",
  secondaryDescription: "Making it easier than ever for players around the world to make data driven decisions about how to improve at events",
  bulletPoints: [
    "Hosts tournament data for 500k Smash Bros Ultimate and Melee players, including 6m sets played across 75k events",
    "Offers in-depth analysis on players to help them identify trends in their performance and prepare for their next event",
    "Indexes over 35k vods from sets played in bracket and connects them to player profiles",
    "Our writers publish articles daily with deep dives into interesting stats drawn from our dataset"
  ],
  tech: ["React.js + Next.js", "Express.js", "Python", "PostgreSQL", "Redis", "AWS RDS", "AWS EB", "Figma"]
};




// Conjure Demo ----------------------------------------------------------------

class ConjureDemo extends React.Component {
  constructor() {
    super();

    // initialize form
    let rerender = function() { this.forceUpdate() }.bind(this);
    let form = new ConjureFormController(SAMPLE_FORM_YC, rerender);
    this.state = {
      conjureForm: form
    };
  }

  render() {
    let mobileCSS = (this.props.renderMobile) ? "mobile" : "landscape";
    return (
      <div id="ConjureDemo" className={mobileCSS}>
        <div id="form-container-container">
          <h3 className="title">Fill out the Survey...</h3>
          <div id="form-container">
            {this.state.conjureForm.renderForm()}
          </div>
        </div>
        <div id="results-container-container">
          <h3 className="title">And see the changes in JSON</h3>
          <div id="results-container">
            {this.state.conjureForm.renderFormResults()}
          </div>
        </div>
      </div>
    );
  }
}



// <HighlightTitleCard/> -------------------------------------------------------

function HighlightTitleCard({
  logo,
  title,
  links,         // list of form [{name, link}]
  primaryColor,
  secondaryColor
}) {

  let linksToRender = [];
  for (let i = 0; i < links.length; i++) {
    let {name, link} = links[i];
    linksToRender.push(
      <a className="link" href={link} target="_blank" style={{'borderColor': primaryColor}}>{name}</a>
    );
  }


  return (
    <div id="HighlightTitleCard" style={{
        'borderColor'    : primaryColor,
        'backgroundColor': secondaryColor
      }}>
      <img id="logo" src={logo} alt={`${title} logo`}/>
      <div id="right-side-container">
        <h1 id="title">{title}</h1>
        {linksToRender}
    </div>
    </div>
  );
}


// <HighlightedProject/> -------------------------------------------------------

function HighlightedProject({
  projectTitle,
  logo,
  links,
  primaryColor,
  secondaryColor,
  primaryDescription,
  secondaryDescription,
  bulletPoints = [],
  renderMobile,
  tech = [],
  children
}) {

  let bulletPointsToRender = [];
  for (let i = 0; i < bulletPoints.length; i++) {
    bulletPointsToRender.push(<li>{bulletPoints[i]}</li>);
  }

  let techToRender = [];
  for (let i = 0; i < tech.length; i++) {
    techToRender.push(
      <p className="tech" style={{'backgroundColor': secondaryColor}}>
        {tech[i]}
      </p>
    );
  }

  return (
    <div id="HighlightedProject">
      <HighlightTitleCard
        title          = {projectTitle}
        logo           = {logo}
        links          = {links}
        primaryColor   = {primaryColor}
        secondaryColor = {secondaryColor}
        />

      <div id="content-container" style={{'borderColor': secondaryColor}}>
        <div id="tech-container">
          {techToRender}
        </div>
        <div className="section-container">
          <h3 className="section-title">{primaryDescription}</h3>
          <p className="section-subtitle">{secondaryDescription}</p>
          <ul>
            {bulletPointsToRender}
          </ul>
        </div>
        {children}
      </div>
    </div>
  );
}
// <HighlightsSection/> --------------------------------------------------------




function HighlightsSection({
  renderMobile,
  scrollToLookup // a lookup {section-name: ref used to scroll to that section}
}) {
  let mobileCSS = (renderMobile) ? "mobile" : "landscape";

  return (
    <div id="HighlightsSection" className={mobileCSS}>
      <h1 id="highlight-title">I build tools that make the complex simple</h1>

      <div className="scroll-to-anchor" ref={scrollToLookup.pgstats}></div>
      <HighlightedProject
        projectTitle         = {PGSTATS.title}
        logo                 = {PGSTATS.logo}
        links                = {PGSTATS.links}
        primaryColor         = {PGSTATS.primaryColor}
        secondaryColor       = {PGSTATS.secondaryColor}
        primaryDescription   = {PGSTATS.primaryDescription}
        secondaryDescription = {PGSTATS.secondaryDescription}
        bulletPoints         = {PGSTATS.bulletPoints}
        tech                 = {PGSTATS.tech}
        renderMobile         = {renderMobile}>

        <div id="link-button-row">
          <a id="go-to-site-link" href="https://pgstats.com" target="_blank" style={{'backgroundColor': PGSTATS.primaryColor}}>
            <h3 id="go-to-site-button">Visit PGstats.com</h3>
          </a>
        </div>
      </HighlightedProject>

      <div className="scroll-to-anchor" ref={scrollToLookup.conjure}></div>
      <HighlightedProject
        projectTitle         = {CONJURE.title}
        logo                 = {CONJURE.logo}
        links                = {CONJURE.links}
        primaryColor         = {CONJURE.primaryColor}
        secondaryColor       = {CONJURE.secondaryColor}
        primaryDescription   = {CONJURE.primaryDescription}
        secondaryDescription = {CONJURE.secondaryDescription}
        bulletPoints         = {CONJURE.bulletPoints}
        tech                 = {CONJURE.tech}
        renderMobile         = {renderMobile}>
        <ConjureDemo renderMobile={renderMobile}/>
      </HighlightedProject>


      <div className="scroll-to-anchor" ref={scrollToLookup.indieoutreach}></div>
      <HighlightedProject
        projectTitle         = {INDIEOUTREACH.title}
        logo                 = {INDIEOUTREACH.logo}
        links                = {INDIEOUTREACH.links}
        primaryColor         = {INDIEOUTREACH.primaryColor}
        secondaryColor       = {INDIEOUTREACH.secondaryColor}
        primaryDescription   = {INDIEOUTREACH.primaryDescription}
        secondaryDescription = {INDIEOUTREACH.secondaryDescription}
        bulletPoints         = {INDIEOUTREACH.bulletPoints}
        tech                 = {INDIEOUTREACH.tech}
        renderMobile         = {renderMobile}>
          <div id="link-button-row">
            <a id="go-to-site-link" href="http://www.indieoutreach.io" target="_blank" style={{'backgroundColor': INDIEOUTREACH.primaryColor}}>
              <h3 id="go-to-site-button" >Start Searching on IndieOutreach</h3>
            </a>
          </div>
      </HighlightedProject>
    </div>
  );
}

export default HighlightsSection;
