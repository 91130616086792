// =============================================================================
// Navbar.js
// =============================================================================
/*

*/


// Imports ---------------------------------------------------------------------

import React from 'react';
import './Navbar.css';


// <NavbarLink/> ---------------------------------------------------------------

function NavbarLink({
  name,
  selected,
  primaryColor,
  secondaryColor,
  scrollToSection
}) {

  let selectedCSS, borderColor, backgroundColor;
  if (selected) {
    selectedCSS = "selected";
    backgroundColor = secondaryColor;
    borderColor = primaryColor;
  } else {
    selectedCSS = "";
    borderColor = secondaryColor;
    backgroundColor = "white";
  }


  return (
    <div id="NavbarLink"
      className={selectedCSS}
      style={{'borderColor': borderColor, 'backgroundColor': backgroundColor}}
      onClick={scrollToSection}>
      <p id="link-text">{name}</p>
    </div>
  );
}

// <Navbar/> -------------------------------------------------------------------

function Navbar({
  selectedSection, // the id of the currently selected section
  scrollToLookup,  // a lookup table of {section-name: ref to section}
  scrollTo         // a function that takes in a ref and scrolls the window to that section
}) {

  return (
    <div id="Navbar">
      <NavbarLink name="Intro"              selected={(selectedSection === 'intro')}  primaryColor="#29EA3C" secondaryColor="#AAFCC1" scrollToSection={() => scrollTo('intro')}/>
      <NavbarLink name="Skills & Education" selected={(selectedSection === 'skills')} primaryColor="#29BBEA" secondaryColor="#AAE8FC" scrollToSection={() => scrollTo('skills')}/>
      <NavbarLink name="Work Experience"    selected={(selectedSection === 'work')}   primaryColor="#FF4242" secondaryColor="#FF7B7B" scrollToSection={() => scrollTo('work')}/>

      <h3 id="title">Highlighted Projects</h3>

      <NavbarLink name="PGstats.com"   selected={(selectedSection === 'pgstats')}       primaryColor="#29BBEA" secondaryColor="#AAE8FC" scrollToSection={() => scrollTo('pgstats')}/>
      <NavbarLink name="Conjure Forms" selected={(selectedSection === 'conjure')}       primaryColor="#EA7A29" secondaryColor="#FCD1AA" scrollToSection={() => scrollTo('conjure')}/>
      <NavbarLink name="IndieOutreach" selected={(selectedSection === 'indieoutreach')} primaryColor="#B45AFB" secondaryColor="#CEAAFC" scrollToSection={() => scrollTo('indieoutreach')}/>

    </div>
  );
}

export default Navbar;
