// =============================================================================
// WorkExperienceSection.js
// =============================================================================
/*
  This file is responsible for <WorkExperienceSection/>
*/

// Imports ---------------------------------------------------------------------

import React from 'react';
import './WorkExperienceSection.css';

import PANDA_LOGO from '../../../Images/panda_white_logo.png';

// Constants -------------------------------------------------------------------

const PRIMARY_COLOR = "#FF4242";
const SECONDARY_COLOR = "#FF7B7B";


// Data ------------------------------------------------------------------------


const BANNER_TEXT = "\"What would you say ya do here?\"";


// <RepeatingBannerText/> ------------------------------------------------------

// this component takes a string and renders it in repeating fashion across the full width of the parent container
function RepeatingBannerText({
  text,
  color,
  offset
}) {

  let spaces = "";
  for (let i = 0; i < text.length / 4; i++) {
    spaces = `${spaces} `;
  }

  // Instead of doing any form of tiling using css properties, it's easiest to just repeat the text multiple times
  let textToRender = `${text}${spaces}`;
  textToRender = `${textToRender}${textToRender}`;
  textToRender = `${textToRender}${textToRender}`;
  textToRender = `${textToRender}${textToRender}`;
  textToRender = `${textToRender}${textToRender}`;
  if (offset) {
    textToRender = `${spaces}${spaces}${spaces}${textToRender}`;
  }

  return (
    <div id="RepeatingBannerText">
      <p id="text" style={{'color': color}}>{textToRender}</p>
    </div>
  );
}


// <WorkCard/> -----------------------------------------------------------------

function WorkCard({
  primaryColor,
  secondaryColor,
  renderMobile
}) {

  let cardCSS = (renderMobile) ? "mobile" : "landscape";
  return (
    <div id="WorkCard" className={cardCSS}>
      <div id="title-row" style={{'backgroundColor': primaryColor}}>
        <h1 id="title">Work Experience</h1>
      </div>
      <div id="content-container" style={{'backgroundColor': secondaryColor}}>
        <div id="positions-container">
          <h3 className="position-name">Fullstack Developer</h3>
          <h3 className="position-name">Database Administrator</h3>
          <h3 className="position-name">Cloud Architect</h3>

          <h1 id="focused-position-name" style={{'backgroundColor': primaryColor}}>Product Engineer</h1>

          <h3 className="position-name">Website Designer</h3>
          <h3 className="position-name">Frontend Developer</h3>
          <h3 className="position-name">"DM @darren on Discord" Guy</h3>
        </div>
        <div id="company-column">
          <div className="small-row"></div>
          <div className="small-row"></div>
          <div id="company-row">
            <h1 id="at-symbol">@</h1>
            <a id="link" href="https://panda.gg" target="_blank">
              <img id="company-logo" src={PANDA_LOGO} alt="Panda Global Logo"/>
            </a>

          </div>
          <div className="small-row">
            <p id="sub-text">Working to make Smash a better esport</p>
          </div>
          <div className="small-row">
            <p id="sub-text-light">September 2020 - Present</p>
          </div>
        </div>
      </div>
    </div>
  );
}


// <WorkExperienceSection/> ----------------------------------------------------

function WorkExperienceSection({
  renderMobile
}) {
  return (
    <div id="WorkExperienceSection">
      <RepeatingBannerText text={BANNER_TEXT} offset={false} color={SECONDARY_COLOR}/>
      <RepeatingBannerText text={BANNER_TEXT} offset={true} color={PRIMARY_COLOR}/>
      <WorkCard
        primaryColor   = {PRIMARY_COLOR}
        secondaryColor = {SECONDARY_COLOR}
        renderMobile   = {renderMobile}
      />
    </div>
  );
}


export default WorkExperienceSection;
