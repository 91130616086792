import React from 'react';
import './HeaderSection.css';

import IMG_FOREST_LANDSCAPE    from '../../../Images/forest_header_with_crown.png';
import IMG_FOREST_MOBILE       from '../../../Images/forest_header_mobile.png';

import IMG_REDBUBBLE from '../../../Images/logo_redbubble.png';
import IMG_GITHUB    from '../../../Images/logo_github.png';
import IMG_SUBSTACK  from '../../../Images/logo_substack.png';
import IMG_LINKEDIN  from '../../../Images/logo_linkedin.png';
import IMG_TWITTER   from '../../../Images/logo_twitter.png';
import IMG_EMAIL     from '../../../Images/logo_email.png';


// Constants -------------------------------------------------------------------

const URL_LINKEDIN  = 'https://linkedin.com/in/thedarrendawson';
const URL_GITHUB    = 'https://github.com/darrendawson';
const URL_TWITTER   = 'https://twitter.com/thedarrendawson';
const URL_SUBSTACK  = 'https://thedarrendawson.substack.com/';
const URL_REDBUBBLE = 'http://redbubble.com/people/dangerousbear/shop';

// <HeaderSection/> ------------------------------------------------------------

function HeaderSection({
  renderMobile
}) {

  let backgroundImageToRender = (renderMobile) ? IMG_FOREST_MOBILE : IMG_FOREST_LANDSCAPE;

  return (
    <div id="HeaderSection">
      <div id="info-container">
        <h1 id="name-text">Darren Dawson</h1>
        <h3 className="item-text">Product Lead</h3>
        <h3 className="item-text">Fullstack Developer</h3>
        <h3 className="item-text">UCSC Class of 2020</h3>
        <div id="icons-container">
          <div className="icons-column">
            <a className="logo_container" href={URL_LINKEDIN}>
              <img className="logo_img" src={IMG_LINKEDIN} alt="linkedin"/>
            </a>
            <a className="logo_container" href={URL_GITHUB}>
              <img className="logo_img" src={IMG_GITHUB} alt="github"/>
            </a>
            <a className="logo_container" href={URL_TWITTER}>
              <img className="logo_img" src={IMG_TWITTER} alt="twitter"/>
            </a>
          </div>
          <div className="icons-column">
            <a className="logo_container" href="mailto:thedarrendawson@gmail.com">
              <img className="logo_img" src={IMG_EMAIL} alt="email"/>
            </a>
            <a className="logo_container" href={URL_REDBUBBLE}>
              <img className="logo_img" src={IMG_REDBUBBLE} alt="redbubble"/>
            </a>
          </div>
        </div>
      </div>
      <img id="header-img" src={backgroundImageToRender} alt="background"/>
    </div>
  );
}

export default HeaderSection;
