import React from 'react';
import './AboutSection.css';

// Constants -------------------------------------------------------------------

const DEFAULT_TEXT = {
  'title': 'Hello there!',
  'subtitle': 'I\'m Darren and I build products for esports',
  'paragraphs': [
    'I\'m the backend dev and in-house designer for the lean dev team over at Panda. My day to day involves making decisions that determine both the technical and product directions for all of our technical projects, be they consumer facing or internal tooling.',
    'For the past year, I\'ve been leading product for the Panda Cup - Nintendo\'s first ever officially licensed Super Smash Bros. Circuit. I\'ve focused the team on rethinking how historical data can be automatically integrated into our broadcasts to help build storylines for our commentators and our viewers to grab hold of.',
    'I wear a lot of hats over at Panda, but at the end of the day they all come back to making smart product decisions to delight the Smash community.'
  ]
}

// <AboutSection/> -------------------------------------------------------------

class AboutSection extends React.Component {

  constructor() {
    super();
    this.state = {};
  }

  // render --------------------------------------------------------------------

  renderParagraphs(paragraphs) {
    let text = [];
    for (let i = 0; i < paragraphs.length; i++) {
      text.push(<p className="paragraph">{paragraphs[i]}</p>)
    }
    return text;
  }


  render() {
    return (
      <div id="AboutSection">
        <div id="content-column">
          <h1 id="title">{DEFAULT_TEXT['title']}</h1>
          <h3 id="subtitle">{DEFAULT_TEXT['subtitle']}</h3>
          {this.renderParagraphs(DEFAULT_TEXT['paragraphs'])}
        </div>
      </div>
    )
  }
}

export default AboutSection;
