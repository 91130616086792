// =============================================================================
// MainContent.js
// =============================================================================
/*
  MainContent.js contains <MainContent/>, a component for rendering the main content / landing page of the portfolio
  This includes all the main categories
    - header
    - about
    - skills
    - education
    - highlights
    - portfolio
    - shirts
*/


// Imports ---------------------------------------------------------------------

import React from 'react';
import './MainContent.css';


import HeaderSection         from './Sections/HeaderSection.js';
import AboutSection          from './Sections/AboutSection.js';
import HighlightsSection     from './Sections/HighlightsSection.js';
import SkillsSection         from './Sections/SkillsSection.js';
import WorkExperienceSection from './Sections/WorkExperienceSection.js';


// <MainContent/> -------------------------------------------------------------------

function MainContent({
  renderMobile,   // boolean
  scrollToLookup // a lookup {section-name: ref for that section so it can be scrolled to}
}) {

  return (
    <div id="MainContent">
      <div id="header-section-container" ref={scrollToLookup.intro}>
        <HeaderSection renderMobile={renderMobile}/>
      </div>
      <div id="about-section-container">
        <div className="invisible">
          <AboutSection/>
        </div>
        <div id="offset-up-container">
          <AboutSection/>
        </div>
      </div>
      <div id="skills-section-container" ref={scrollToLookup.skills}>
        <SkillsSection renderMobile={renderMobile}/>
      </div>
      <div id="work-experience-container" ref={scrollToLookup.work}>
        <WorkExperienceSection renderMobile={renderMobile}/>
      </div>
      <div id="highlights-secion-container">
        <HighlightsSection renderMobile={renderMobile} scrollToLookup={scrollToLookup}/>
      </div>
    </div>
  );
}

export default MainContent;
