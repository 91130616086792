// =============================================================================
// App.js
// =============================================================================
/*
  App.js contains <App/>, which is the root component of the portfolio site.
*/


// Imports ---------------------------------------------------------------------

import React from 'react';
import './App.css';

import REACT_LOGO from './logo.svg';

// Components
import MainContent from './Components/MainContent/MainContent.js';
import Navbar      from './Components/Navbar.js';


// <App/> ----------------------------------------------------------------------

class App extends React.Component {

  constructor() {
    super();


    // create page anchors to pass around so user can jump to sections
    this.pageRefs = {
      intro        : React.createRef(),
      skills       : React.createRef(),
      work         : React.createRef(),
      pgstats      : React.createRef(),
      conjure      : React.createRef(),
      indieoutreach: React.createRef()
    };

    this.sectionsOrder = ['intro', 'skills', 'work', 'pgstats', 'conjure', 'indieoutreach'];


    this.state = {
      window: {
        width : 0,
        height: 0
      },
      selectedSection: this.sectionsOrder[0]
    };

    // bind functions so they can access state
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({'window': {width: window.innerWidth, height: window.innerHeight}});
  }


  // this function will scroll the page to the selected section
  handleScrollToSection = (sectionName) => {
    if (sectionName in this.pageRefs) {
      let ref = this.pageRefs[sectionName];
      ref.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  // This function will change the selected section based off of scroll position
  setSelectedSectionOnScroll = () => {
    for (let i = 0; i < this.sectionsOrder.length; i++) {

      let sectionName = this.sectionsOrder[i];
      let sectionRef = this.pageRefs[sectionName];
      let {top, bottom} = sectionRef.current.getBoundingClientRect();

      // check to see if this section is at the top of the page
      if (top >= -3 && top < 200) {
        if (this.state.selectedSection !== sectionName) {
          this.setState({selectedSection: sectionName});
        }
        return;
      }

      // we only want to look for the div that is at the top of the page, so quit if we've gone too far
      // -> this is for performance
      if (top > 200) {

        // if we are on the last element of the page, it may not be able to get to the top (but we still want to select it)
        if (i + 1 === this.sectionsOrder.length && this.state.selectedSection !== sectionName) {

          // make sure we don't cut off the 2nd to last item if the last item is barely visible
          if (this.state.window.height - bottom > 400) {
            this.setState({selectedSection: sectionName});
          }
        }
        return;
      }
    }
  }


  // render --------------------------------------------------------------------


  renderMainContent() {
    let mobile = (this.state.window.width > 0 && this.state.window.width < 700);

    return (
      <div id="main-content-container" className={(mobile) ? "mobile": ""}>
        <MainContent
          renderMobile   = {mobile}
          scrollToLookup = {this.pageRefs}
        />
      </div>
    );
  }

  // renders <App/>
  render() {

    // render the App in "landscape" mode
    // -> this has a sort of sidebar that shows sections
    if (this.state.window.width > this.state.window.height * 1.3 && this.state.window.width > 1000 + 500 + 10) {
      return (
        <div id="App">
          <div id="landscape-container"  onScroll={this.setSelectedSectionOnScroll}>
            <div id="sidebar-container">
              <div id="sidebar-absolute">
                <Navbar
                  scrollToLookup={this.pageRefs}
                  scrollTo={this.handleScrollToSection}
                  selectedSection={this.state.selectedSection}
                  />
                <div id="cheeky-made-with-container">
                  <p className="made-with-text">Made with</p>
                  <img id="react-logo" src={REACT_LOGO} alt="react"/>
                  <p className="made-with-text">in California</p>
                </div>
              </div>
            </div>
            <div  >
              <div id="main-content-column">
                {this.renderMainContent()}
              </div>
            </div>
          </div>
        </div>
      )
    }

    // render the "portrait" style website
    return (
      <div id="App" className="mobile-sized">
        <div id="portrait-container">
          {this.renderMainContent()}
        </div>
      </div>
    )
  }
}

export default App;
