// =============================================================================
// SkillsSection.js
// =============================================================================
/*
  Section shows off skills (and education)
*/

// Imports ---------------------------------------------------------------------

import React from 'react';
import './SkillsSection.css';

// Constants -------------------------------------------------------------------

const RENDER_AS_SINGLE_COLUMN = true;
const RENDER_AS_MULTI_COLUMNS = false;

// Data ------------------------------------------------------------------------

const FULLSTACK_CARD = {
  title: 'As a Fullstack Dev',
  colors: {
    primary: '#29BBEA',
    secondary: '#AAE8FC'
  },
  options: [
    {
      title: 'Languages',
      description: '',
      options: ['Python', 'Javascript', 'SQL', 'GraphQL']
    },
    {
      title: 'Frontend Frameworks',
      description: '',
      options: ['React.js', 'Next.js']
    },
    {
      title: 'Backend Stuff',
      description: '',
      options: ['Express.js', 'Redis']
    },
    {
      title: 'Databases',
      description: '',
      options: ['Postgres', 'MySQL', 'SQLite']
    },
    {
      title: 'Cloud Hosting (AWS)',
      description: '',
      options: ['Elastic Beanstalk', 'Relational Database Service', 'Route 53']
    },
  ]
};

const DESIGN_CARD = {
  title: 'In the Design Department',
  colors: {
    primary: '#EA7A29',
    secondary: '#FCD1AA'
  },
  options: [
    {
      title: 'Tools of the Trade',
      description: '',
      options: ['Figma', 'Gimp', 'Pen & Paper']
    }
  ]
};

const EDUCATION_CARD = {
  title: 'Fairly Well Educated',
  colors: {
    primary: '#29EA3C',
    secondary: '#AAFCC1'
  },
  options: [
    {
      title: 'University of California, Santa Cruz',
      description: 'September 2016 - June 2020, 3.4 GPA',
      options: ['B.S. in Computer Science', 'B.S. in Technology & Information Management']
    },
    {
      title: 'Y-Combinator, Startup School',
      description: 'July 2018 - November 2018, Advisory Track',
      options: ['Launched IndieOutreach during the program']
    }
  ]
};

// SkillCard -------------------------------------------------------------------


function SkillCardOption({
  title,        // the title of the bullet point list
  description,  // an optional description (like a subtitle)
  options,      // a list of text strings to be rendered as a bullet point list
}) {

  let optionsToRender = [];
  for (let i = 0; i < options.length; i++) {
    optionsToRender.push(<li id="options-list-item">{options[i]}</li>);
  }

  return (
    <div id="SkillCardOption">
      <h3 id="title">{title}</h3>
      <p id="description">{description}</p>
      <ul id="options-list">{optionsToRender}</ul>
    </div>
  );
}



function SkillCard({
  title,          // the name of the card
  primaryColor,   // the darker color
  secondaryColor, // the lighter color
  options,        // items within the card, a list of form [{title, description, options: [list, of, strings]}]
  singleColumn    // true if we want to render the options in a single column, false if we want to render in 2
}) {

  let optionWrapper = (singleColumn) ? "skill-card-option-wrapper-full-width" : "skill-card-option-wrapper-half-width";
  let optionsToRender = [];
  for (let i = 0; i < options.length; i++) {
    let option = options[i];
    optionsToRender.push(
      <div id={optionWrapper}>
        <SkillCardOption
          title       = {option.title}
          description = {option.description}
          options     = {option.options}
          />
      </div>
    );
  }


  return (
    <div id="SkillCard">
      <div id="top-bar" style={{'backgroundColor': primaryColor}}></div>
      <div id="title-bar" style={{'backgroundColor': secondaryColor}}>
        <h1 id="title">{title}</h1>
      </div>
      <div id="main-content-section" style={{'border': `2px solid ${secondaryColor}`}}>
        {optionsToRender}
      </div>
    </div>
  );
}

// <SkillsSection/> ------------------------------------------------------------

function SkillsSection({
  renderMobile
}) {

  function renderCard(card, singleColumn = true) {
    return (
      <SkillCard
        title          = {card.title}
        primaryColor   = {card.colors.primary}
        secondaryColor = {card.colors.secondary}
        options        = {card.options}
        singleColumn   = {singleColumn}
        />
    );
  };

  if (renderMobile) {
    return (
      <div id="SkillsSection">
        <div id="mobile-column">
          {renderCard(FULLSTACK_CARD, RENDER_AS_MULTI_COLUMNS)}
          {renderCard(DESIGN_CARD, RENDER_AS_SINGLE_COLUMN)}
          {renderCard(EDUCATION_CARD, RENDER_AS_SINGLE_COLUMN)}
        </div>
      </div>
    );
  }
  return (
    <div id="SkillsSection">
      <div id="column" style={{'width': '50%'}}>
        {renderCard(FULLSTACK_CARD, RENDER_AS_MULTI_COLUMNS)}
      </div>
      <div id="column">
        {renderCard(DESIGN_CARD, RENDER_AS_SINGLE_COLUMN)}
        {renderCard(EDUCATION_CARD, RENDER_AS_SINGLE_COLUMN)}
      </div>
    </div>
  );
}


export default SkillsSection;
